<!-- =========================================================================================
  File Name: CourseEditTabInformation.vue
  Description: Course Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/course/pixinvent
========================================================================================== -->

<template>
  <div id="course-edit-tab-info">
    <!-- Content Row -->

    <!-- Col Header -->
    <div class="flex items-end">
      <feather-icon class="mr-2" icon="CourseIcon" svgClasses="w-5 h-5"/>
      <span class="leading-none font-medium">Course Information</span>
    </div>

    <div class="vx-row pb-10">
      <div class="vx-col md:w-1/4 w-full">
        <vs-input class="w-full mt-4" label="Course Name" name="course_name" v-model="data_local.name" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('course_name')">{{ errors.first('course_name') }}</span>
      </div>
      <div class="vx-col md:w-1/4 w-full">
        <vs-input class="w-full mt-4" label="Course Preview ID" name="course_preview_id" v-model="data_local.preview_id" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('course_preview_id')">{{ errors.first('course_preview_id') }}</span>
      </div>
      <div class="vx-col md:w-1/4 w-full">
        <vs-input class="w-full mt-4" label="Course Author" name="course_author" v-model="data_local.author_id" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('course_author')">{{ errors.first('course_author') }}</span>
      </div>
      <div class="vx-col md:w-1/4 w-full">
        <vs-input class="w-full mt-4" label="Advertised Price" name="course_advertised_price" v-model="data_local.advertised_price_id" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('course_advertised_price')">{{ errors.first('course_advertised_price') }}</span>
      </div>

      <div class="vx-col w-full mt-4">
        <vs-textarea height="125px" class="w-full" label="Course Description" name="course_description" v-model="data_local.description"/>
      </div>

      <div class="vx-col w-full mt-4">
        <vs-textarea height="125px" class="w-full" label="Course Introduction" name="course_introduction" v-model="data_local.introduction"/>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button @click="initiateCourseUpdate" class="ml-auto mt-2">Save Changes</vs-button>
          <vs-button @click="resetData" class="ml-4 mt-2" color="warning" type="border">Reset</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
      services: [],
    };
  },
  mounted() {

  },
  methods: {
    initiateCourseUpdate() {

      const payload = {
        name: this.data_local.name,
        description: this.data_local.description,
        introduction: this.data_local.introduction,
        preview_id: this.data_local.preview_id,
        author_id: this.data_local.author_id,
        advertised_price_id: this.data_local.advertised_price_id
      };

      this.$vs.loading();

      this.$http.post(`courses/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {

            this.data_local = response.data.data;
            let pictureId = this.data_local.image || 'default';
            this.data_local.photoURL = `https://app-cdn.rapidseminars.com/course-pictures/${pictureId}.jpeg`;

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s settings have been updated`,
              iconPack: 'feather',
              icon: 'icon-course-check',
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();



          let response;
          if (exception.response) {
            response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            iconPack: 'feather',
            icon: 'icon-course-x',
            color: 'danger',
          });
        });
    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style>
.avatar {
  width: 25rem;
}
</style>
