<!-- =========================================================================================
  File Name: CourseEditTabInformation.vue
  Description: Course Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/course/pixinvent
========================================================================================== -->

<template>
  <div id="course-edit-tab-info">
    <!-- Content Row -->

    <!-- Col Header -->
    <div class="flex items-end">
      <feather-icon class="mr-2" icon="CourseIcon" svgClasses="w-5 h-5"/>
      <span class="leading-none font-medium">Course Images</span>
    </div>

    <div class="vx-col lg:w-1/5 w-full">

      <div class="vx-row pt-5">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col sm:flex-row">
            <img :src="data_local.photoURL" alt="Course Image" class="avatar mr-8 rounded"/>
            <div>
              <input id="file" ref="file" class="d-none pb-2 mt-3" type="file" @change="selectFile"><br/>
              <vs-button class="mr-4 sm:mb-0 mb-2" @click="submitPicture">Upload photo</vs-button>
              <p class="text-sm mt-2">Allowed JPG or PNG. Max size of 15MB</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
      services: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  mounted() {

  },
  methods: {
    selectFile() {
      const files = this.$refs.file.files;

      if (files.length > 0) {
        this.file = files[0];
      }

    },
    submitPicture() {

      if (typeof this.file !== 'object') {
        return this.$vs.notify({
          title: 'Error',
          text: 'You must select a file to upload',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      }
      this.$vs.loading();

      const formData = new FormData();
      formData.append('upload', this.file);

      this.$http.post(`courses/${this.data.id}/thumbnail`, formData)
        .then((response) => {
          this.$vs.loading.close();

          if (response.response) {
            response = response.response;
          }

          if (response.data.data) {

            this.data_local = response.data.data;
            this.data_local.photoURL = `https://app-cdn.rapidseminars.com/thumbnails/${
              this.data_local.thumbnail_id || 'default'
            }.jpeg`;

            this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s' settings have been updated`,
              color: 'success',
            });
          }

        })
        .catch(e => {
          this.$vs.loading.close();
        });

    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style>
.avatar {
  width: 25rem;
}
</style>
