<!-- =========================================================================================
  File Name: CourseEditTabInformation.vue
  Description: Course Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/course/pixinvent
========================================================================================== -->

<template>
  <div id="course-edit-tab-info">
    <!-- Content Row -->

    <!-- Col Header -->
    <div class="flex items-end">
      <feather-icon class="mr-2" icon="CourseIcon" svgClasses="w-5 h-5"/>
      <span class="leading-none font-medium">Course Additional Information</span>
    </div>

    <div class="vx-row pb-10 pt-8">

      <div class="vx-col w-full">
        <label class="vs-input--label">Intended Audience</label>

        <vs-chips v-model="data_local.intended_audience" placeholder="Add Audience">
          <vs-chip
            v-for="chip in data_local.intended_audience"
            :key="chip"
            closable
            color="secondary"
            @click="remove(data_local.intended_audience, chip)">
            {{ chip }}
          </vs-chip>
        </vs-chips>

      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Intended Outcome</label>

        <vs-chips v-model="data_local.intended_outcomes" placeholder="Add Outcome">
          <vs-chip
            v-for="chip in data_local.intended_outcomes"
            :key="chip"
            closable
            color="secondary"
            @click="remove(data_local.intended_outcomes, chip)">
            {{ chip }}
          </vs-chip>
        </vs-chips>

      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Prerequisites</label>

        <vs-chips v-model="data_local.prerequisites" placeholder="Add Prerequisites">
          <vs-chip
            v-for="chip in data_local.prerequisites"
            :key="chip"
            closable
            color="secondary"
            @click="remove(data_local.prerequisites, chip)">
            {{ chip }}
          </vs-chip>
        </vs-chips>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" class="ml-auto mt-2" @click="initiateCourseUpdate">
            Save Changes
          </vs-button>
          <vs-button class="ml-4 mt-2" color="warning" type="border" @click="resetData">Reset</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
      services: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  mounted() {

  },
  methods: {
    remove(array, item) {
      array.splice(array.indexOf(item), 1);
    },
    initiateCourseUpdate() {

      const payload = {
        intended_outcomes: this.data_local.intended_outcomes,
        intended_audience: this.data_local.intended_audience,
        prerequisites: this.data_local.prerequisites,
      };

      this.$vs.loading();

      this.$http.post(`courses/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {
            this.data_local = response.data.data;

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s settings have been updated`,
              iconPack: 'feather',
              icon: 'icon-course-check',
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();



          let response;
          if (exception.response) {
            response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            iconPack: 'feather',
            icon: 'icon-course-x',
            color: 'danger',
          });
        });
    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style type="text/scss">
.avatar {
  width: 25rem;
}


.con-chips--input {
  border-style: solid !important;
  border-color: $theme-background !important;
  border-width: 1px !important;
  border-radius: 5px;
}
</style>
