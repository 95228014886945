<!-- =========================================================================================
  File Name: CourseEdit.vue
  Description: Course Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/course/pixinvent
========================================================================================== -->

<template>
  <div id="page-course-edit">

    <vs-alert :active.sync="course_not_found" color="danger" title="Course Not Found">
      <span>Course record with id: {{ $route.params.courseId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link class="text-inherit underline" to="/a/courses">All Courses</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="course_data">

      <div class="tabs-container px-6 pt-6" slot="no-body">

        <vs-tabs class="tab-action-btn-fill-conatiner" v-model="activeTab">
          <vs-tab icon="icon-settings" icon-pack="feather" label="General">
            <div class="tab-text">
              <course-edit-tab-general :data="course_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-plus-square" icon-pack="feather" label="Additional">
            <div class="tab-text">
              <course-edit-tab-additional :data="course_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-image" icon-pack="feather" label="Images">
            <div class="tab-text">
              <course-edit-tab-images :data="course_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-package" icon-pack="feather" label="Children">
            <div class="tab-text">
              <course-edit-tab-children :data="course_data" class="mt-4"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import CourseEditTabGeneral from './CourseEditTabGeneral.vue';
import CourseEditTabAdditional from './CourseEditTabAdditional.vue';
import CourseEditTabImages from './CourseEditTabImages.vue';
import CourseEditTabChildren from './CourseEditTabChildren.vue';

// Store Module

export default {
  components: {
    CourseEditTabGeneral,
    CourseEditTabAdditional,
    CourseEditTabImages,
    CourseEditTabChildren,
  },
  data() {
    return {
      course_data: null,
      course_not_found: false,
      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.fetchCourseData(this.$route.params.courseId);
    },
  },
  methods: {
    fetchCourseData(courseId) {

      this.$http.get(`courses/${courseId}/rich`)
        .then(response => {

          if (response.data.data) {
            this.course_data = response.data.data;
            const pictureId = this.course_data.thumbnail_id || 'default';
            this.course_data.photoURL = `https://app-cdn.rapidseminars.com/thumbnails/${pictureId}.jpeg`;
          }

        })
        .catch(error => {

          if (error.response.status === 404) {
            this.course_not_found = true;
            return;
          }
        });
    },
  },
  created() {
    this.fetchCourseData(this.$route.params.courseId);
  },
};

</script>
